import { Box } from "@chakra-ui/react"
import { BaseTemplate } from "./base"

interface HomeTemplateProps {
  logo: JSX.Element
  homeMenu: JSX.Element
}

export const HomeTemplate = ({ logo, homeMenu }: HomeTemplateProps) => {
  return (
    <BaseTemplate>
      <Box display="flex" alignItems="center" justifyContent="center" width="100%" height="100%" position="relative">
        <Box position="absolute" top="0px" left="0" padding="8px 20px">
          {logo}
        </Box>
        <Box display="flex" justifyContent="center" width="988px" height="524px">
          {homeMenu}
        </Box>
      </Box>
    </BaseTemplate>
  )
}
