import { Box, BoxProps, Text } from "@chakra-ui/react"

interface MenuItemProps extends Partial<Pick<BoxProps, "width" | "height">> {
  heading: string
  subheading?: string
  backgroundImage?: string
  backgroundVideo?: string
  disabled?: boolean
  onClick?: () => void
}

export const MenuItem = ({
  heading,
  subheading,
  backgroundImage,
  backgroundVideo,
  onClick,
  ...rest
}: MenuItemProps) => {
  return (
    <Box
      width="100%"
      height="100%"
      boxShadow="0px 1px 6px 1px #13131368"
      border="1px solid"
      borderColor="#a8a8a829"
      role="group"
      transition="border-color 0.4s ease-in-out"
      _hover={{ borderColor: "#a8a8a863" }}
      borderRadius="10px"
      overflow="hidden"
    >
      <Box
        width="100%"
        height="100%"
        cursor={"pointer"}
        position="relative"
        display="flex"
        justifyContent="center"
        alignItems="center"
        onClick={onClick}
        backgroundImage={backgroundImage}
        backgroundPosition="center"
        backgroundSize="cover"
        backgroundRepeat="no-repeat"
        aria-label={heading}
        {...rest}
      >
        {backgroundVideo && (
          <video
            src={backgroundVideo}
            preload="auto"
            muted
            loop
            style={{ position: "absolute", top: "0", left: "0", width: "100%", height: "100%", objectFit: "cover" }}
            onMouseOver={(event) => event.currentTarget.play()}
            onMouseOut={({ currentTarget }) => currentTarget.pause()}
          />
        )}

        <Box
          className="overlay"
          pointerEvents="none"
          position="absolute"
          top="0"
          left="0"
          right="0"
          bottom="0"
          background="linear-gradient(to bottom, rgba(46, 46, 46, 0.5) 0%, rgba(46, 46, 46, 0.3) 30%, rgba(46, 46, 46, 0.1) 50%, rgba(46, 46, 46, 0.3) 70%, rgba(46, 46, 46, 0.5)) 100%"
          boxShadow="inset 0px 0px 84px 24px #14181b"
          _groupHover={{
            opacity: 0.3,
          }}
          transition="opacity linear 0.3s"
        />

        <Box className="text-group" pointerEvents="none" width="100%" height="100%" color="white">
          <Box
            paddingX="26px"
            paddingY="10px"
            position="absolute"
            bottom="0px"
            background="#2f2e2e4a"
            boxShadow="0 -4px 20px #c3c3c31d"
            backdropFilter="blur(4px)"
            borderTop="1px solid #a8a8a829"
            transition="box-shadow 0.4s ease-in-out"
            _groupHover={{
              boxShadow: "0 -4px 20px #c3c3c352",
            }}
            width="100%"
          >
            <Text fontSize="24px" color={"white"} fontWeight="medium" mb="4px" lineHeight="30px">
              {heading}
            </Text>
            <Text fontSize="14px" color={"#a7a7a7"}>
              {subheading}
            </Text>
          </Box>
        </Box>
      </Box>
    </Box>
  )
}
