import { Box, Center, Fade, HStack, VStack } from "@chakra-ui/react"
import { keyframes } from "@emotion/react"
import { Logo, LogoTitle, LogoSubTitle } from "@/components/atoms/Logo"

export const Loading = ({ isLoading, onAnimationEnd }: { isLoading: boolean; onAnimationEnd: () => void }) => {
  const fillKeyframes = keyframes`
    from { fill: transparent; }
    to { fill: #352d87; }
  `

  return (
    <Box zIndex="5">
      <Fade in={isLoading} unmountOnExit transition={{ enter: { duration: 0 }, exit: { duration: 0.3 } }}>
        <Center width="100vw" height="100vh" position="fixed" left="0" top="0" backgroundColor="white">
          <HStack transform={{ base: "scale(1)", lg: "scale(2)" }} spacing="14px">
            <Logo animation={`${fillKeyframes} 1.5s ease forwards`} />
            <VStack alignItems="center" spacing="9px">
              <LogoTitle animate onAnimationEnd={onAnimationEnd} />
              <LogoSubTitle animate />
            </VStack>
          </HStack>
        </Center>
      </Fade>
    </Box>
  )
}
