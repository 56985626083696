import { useEffect } from "react"
import { useRouter } from "next/router"
import { useQueryClient } from "react-query"

import { useShip } from "@/apis/ship"
import { useSystem } from "@/apis/system"
import { PRODUCTS } from "@/constants/products"

interface UseInstalledSystemProps {
  redirect?: boolean
}

export const useInstalledSystem = ({ redirect }: UseInstalledSystemProps) => {
  const router = useRouter()
  const queryClient = useQueryClient()

  const {
    data: systemData,
    isLoading: isLoadingSystemData,
    isError: isErrorSystemData,
  } = useSystem({
    retry: false,
    staleTime: Infinity,
    onSuccess: (data) => {
      localStorage.setItem("system-license", JSON.stringify(data))
    },
    onError: () => {
      const systemLicense = localStorage.getItem("system-license")
      if (!systemLicense) return
      queryClient.setQueryData("system", JSON.parse(systemLicense))
    },
  })
  const {
    data: shipData,
    isLoading: isLoadingShipData,
    isError: isErrorShipData,
  } = useShip({
    retry: false,
    staleTime: Infinity,
    onSuccess: (data) => {
      localStorage.setItem("ship-spec", JSON.stringify(data))
    },
    onError: () => {
      const shipSpec = localStorage.getItem("ship-spec")
      if (!shipSpec) return
      queryClient.setQueryData("ship", JSON.parse(shipSpec))
    },
  })

  useEffect(() => {
    if (isLoadingSystemData || isLoadingShipData) {
      return
    }
    if (systemData && shipData) {
      return
    }
    if (router.asPath.startsWith("/settings")) {
      return
    }
    if (!redirect) {
      return
    }

    router.replace("/enablement")
  }, [systemData, shipData, isLoadingSystemData, isLoadingShipData])

  return {
    hasNAS: Boolean(systemData?.products?.includes(PRODUCTS.HiNAS)),
    hasBAS: Boolean(systemData?.products?.includes(PRODUCTS.HiBAS)),
    NumberOfEngines: Number(shipData?.no_of_engines),
    hasAllSystem: systemData?.products?.length === 2,
    emptyProduct: systemData?.products?.length === 0,
    hasOnlyBAS: systemData?.products?.length !== 2 && Boolean(systemData?.products?.includes(PRODUCTS.HiBAS)),
    isLoading: isLoadingSystemData,
    isError: isErrorSystemData,
    systemData,
    shipData,
  }
}
