import { useQuery, UseQueryOptions } from "react-query"

import { keys } from "./query-keys"
import { VesselSpecification } from "@/types/vessel"
import { settingsClient } from "../settings-client"

export const useShip = (
  options?: UseQueryOptions<VesselSpecification, Error, VesselSpecification, typeof keys.default>
) => {
  return useQuery(keys.default, fetchShip, options)
}

const fetchShip = async () => {
  const { data } = await settingsClient.get("/vessel")

  return data
}
