import { useMutation, MutationOptions, useQueryClient } from "react-query"

import { VesselSpecification } from "@/types/vessel"
import { keys } from "./query-keys"
import { settingsClient } from "../settings-client"

export const useAddShip = (options?: MutationOptions<VesselSpecification, Error, VesselSpecification>) => {
  const queryClient = useQueryClient()

  return useMutation((payload) => addShip(payload), {
    onSuccess: () => {
      queryClient.invalidateQueries(keys.default)
    },
    ...options,
  })
}

const addShip = async (payload: VesselSpecification) => {
  const { data } = await settingsClient.post("/vessel", payload)

  return data
}
