import { useQuery, UseQueryOptions } from "react-query"

import { keys } from "./query-keys"
import { SystemSpecification } from "@/types/system"
import { settingsClient } from "../settings-client"

export const useSystem = (
  options?: UseQueryOptions<SystemSpecification, Error, SystemSpecification, typeof keys.default>
) => {
  return useQuery(keys.default, fetchSystem, options)
}

const fetchSystem = async () => {
  const { data } = await settingsClient.get("/shipowner")

  return data
}
