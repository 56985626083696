import { useState, useEffect } from "react"
import { useRouter } from "next/router"
import { Box, Center, Flex } from "@chakra-ui/react"

import { MenuItem } from "@/components/molecules/home/MenuItem"
import { Loading } from "@/components/molecules/Loading"
import { useInstalledSystem } from "@/hooks/useInstalledSystem"
import { useSessionStorage } from "@/hooks/useSessionStorage"
import { useErrorModal } from "@/hooks/useErrorModal"

export const HomeMenu = () => {
  const router = useRouter()
  const { hasNAS, hasBAS, systemData, shipData, emptyProduct, hasOnlyBAS } = useInstalledSystem({ redirect: false })
  const [sessionEnterValue, setSessionEnterValue] = useSessionStorage<null>("is_initial_page_loaded", null)
  const [isAnimationEnd, setIsAnimationEnd] = useState(false)

  const onAnimationEnd = () => {
    setTimeout(() => {
      setIsAnimationEnd(true)
    }, 1300)
  }

  const modal = useErrorModal()

  const handleMenuClick = (menu: string) => {
    if (menu === "settings/vessel" && (!systemData || !shipData)) {
      router.push({
        pathname: "/login",
        query: { returnURL: "/enablement" },
      })

      return
    }
    if (menu !== "HiNAS" && menu !== "HiBAS") {
      router.push({
        pathname: "/login",
        query: { returnURL: `/${menu}` },
      })
      return
    }

    const hasProduct = menu === "HiBAS" ? hasBAS : hasNAS
    if (hasProduct) {
      if (menu === "HiBAS") {
        router.push(`/bas`)
      }
      if (menu === "HiNAS") {
        router.push(`/nas`)
      }

      return
    }

    modal({ message: `You don't have ${menu}` })
  }

  useEffect(() => {
    if (sessionStorage.getItem("is_initial_page_loaded") || isAnimationEnd) {
      setSessionEnterValue("yes")

      return
    }

    setSessionEnterValue("no")
  }, [isAnimationEnd])

  return (
    <Center flexGrow={1} display="flex">
      <Loading isLoading={sessionEnterValue === "no"} onAnimationEnd={onAnimationEnd} />
      <Box width="100%" height="100%" display="flex" justifyContent="center" gap="20px">
        {(emptyProduct || hasBAS) && (
          <Box flexBasis="28%">
            <MenuItem
              heading="HiBAS"
              subheading="Berthing Assistant System"
              height="100%"
              disabled={!hasBAS}
              backgroundVideo="/assets/videos/background-bas.mp4"
              onClick={() => handleMenuClick("HiBAS")}
            />
          </Box>
        )}
        <Box flexBasis={hasOnlyBAS ? "42%" : "72%"} display="flex" flexDirection="column" gap="20px">
          {(emptyProduct || hasNAS) && (
            <Box flexBasis="50%">
              <MenuItem
                heading="HiNAS"
                subheading="Navigation Assistant System"
                height="100%"
                backgroundVideo="/assets/videos/background-nas.mp4"
                disabled={!hasNAS}
                onClick={() => handleMenuClick("HiNAS")}
              />
            </Box>
          )}
          <Box
            display="flex"
            flexBasis={hasOnlyBAS ? "100%" : "50%"}
            gap="20px"
            flexDirection={hasOnlyBAS ? "column-reverse" : "row"}
          >
            <Box flexBasis={hasOnlyBAS ? "50%" : "62%"} maxWidth="440px">
              <MenuItem
                heading="Voyage Recorder"
                subheading="Voyage Black Box"
                height="100%"
                backgroundVideo="/assets/videos/background-voyage.mp4"
                onClick={() => handleMenuClick("blackbox")}
              />
            </Box>
            <Box flexBasis={hasOnlyBAS ? "50%" : "38%"} maxWidth="260px">
              <MenuItem
                heading="Settings"
                subheading="Adjust & Customize"
                height="100%"
                backgroundVideo="/assets/videos/background-settings.mp4"
                onClick={() => handleMenuClick("settings/vessel")}
              />
            </Box>
          </Box>
        </Box>
      </Box>
    </Center>
  )
}
