import React, { useState } from "react"

export const useSessionStorage = <T,>(key: string, initialValue: T) => {
  const [storedValue, setStoredValue] = useState(() => {
    if (typeof window === "undefined") {
      return initialValue
    }
    try {
      const item = window.sessionStorage.getItem(key)

      return item ? JSON.parse(item) : initialValue
    } catch (error) {
      return initialValue
    }
  })

  const setValue = (value: string) => {
    setStoredValue(value)
    window.sessionStorage.setItem(key, value)
  }

  return [storedValue, setValue]
}
