import { useMutation, useQueryClient, MutationOptions } from "react-query"

import { keys } from "./query-keys"
import { settingsClient } from "../settings-client"

export const useSyncSystem = (options?: MutationOptions) => {
  const queryClient = useQueryClient()

  return useMutation(fetchSyncShip, {
    onSuccess: () => {
      queryClient.invalidateQueries(keys.default)
    },
    ...options,
  })
}

const fetchSyncShip = async () => {
  const { data } = await settingsClient.put("/shipowner")

  return data
}
