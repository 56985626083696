import { IconFail } from "@/components/atoms/icon"
import { Box, Button, Divider, HStack, Text, useToast, UseToastOptions } from "@chakra-ui/react"

export const useErrorModal = () => {
  const toast = useToast()

  const modal = ({
    message,
    closeText,
    onCloseComplete,
    statusCode,
  }: {
    message: string | React.ReactNode
    closeText?: string
    onCloseComplete?: () => void
    statusCode?: string | number
  }) => {
    if (typeof message === "string" && toast.isActive(message)) return

    const options: UseToastOptions = {
      id: typeof message === "string" ? message : undefined,
      duration: 1000 * 60 * 30,
      position: "bottom",
      isClosable: true,
      render: ({ onClose }) => {
        return <ErrorModal onClose={onClose} message={message} closeText={closeText} statusCode={statusCode} />
      },
      onCloseComplete,
    }

    toast(options)
  }

  return modal
}

interface ErrorModalProps {
  title?: React.ReactNode
  closeText?: string
  message: string | React.ReactNode
  statusCode?: string | number
  onClose: () => void
}

export const ErrorModal = ({ onClose, title = "Error", closeText = "Close", statusCode, message }: ErrorModalProps) => {
  return (
    <Box
      position="fixed"
      left="0"
      bottom="0"
      right="0"
      top="0"
      width="100% !important"
      height="100% !important"
      display="flex"
      alignItems="center"
      justifyContent="center"
      userSelect="none"
    >
      <Box
        minWidth="770px"
        id="error-modal"
        background={"rgba(0, 0, 0, 0.65)"}
        backdropFilter="blur(10px)"
        border={"5px solid rgba(255, 255, 255, 0.2)"}
        borderRadius="10px"
        paddingTop="30px"
        paddingRight="30px"
        paddingBottom="30px"
        paddingLeft="30px"
        color="white"
      >
        <Box fontSize="30px" fontWeight="500" marginBottom="16px">
          <HStack spacing="16px">
            <IconFail boxSize="34px" />
            <Box>{`${title}${statusCode ? ` - ${statusCode}` : ""}`}</Box>
          </HStack>
        </Box>
        <Divider backgroundColor="rgba(255, 255, 255, 0.2)" marginBottom="8px" />
        <Box padding="32px 4px" marginBottom="16px" display="flex" alignItems="center" borderBottomRadius="2px">
          <Text fontSize="20px" fontWeight="500" color={"white"} textShadow="1px 1px 2px rgba(0, 0, 0, 0.75)">
            {message}
          </Text>
        </Box>
        <Box>
          <Button
            height="42px"
            width="100%"
            backgroundColor={"rgba(255, 255, 255, 0.2)"}
            _hover={{ backgroundColor: "rgba(60, 60, 60, 0.655)" }}
            _active={{ backgroundColor: "rgba(60, 60, 60, 0.655)" }}
            onClick={onClose}
          >
            <Text as="span" color="white" fontSize="20px" fontWeight="500" textShadow="1px 1px 2px rgba(0, 0, 0, 0.75)">
              {closeText}
            </Text>
          </Button>
        </Box>
      </Box>
    </Box>
  )
}
