import { Logo, LogoTitle } from "@/components/atoms/Logo"
import { HStack } from "@chakra-ui/react"

export const LogoWithTitle = ({ onClick }: { onClick?: () => void }) => {
  return (
    <HStack padding="0px 8px" alignItems="center" cursor="pointer" onClick={onClick}>
      <Logo fill="white" boxSize="38px" />
      <LogoTitle width="105px" />
    </HStack>
  )
}
